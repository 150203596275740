$(document).ready(function() {
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	/*$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
		event.preventDefault();
		$(this).ekkoLightbox();
	});*/

	// select2 set default theme
	//$.fn.select2.defaults.set( "theme", "bootstrap" );
});


// Google Analytics
function ga_init() {
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
		m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

	ga('create', gaTrackingCode, 'auto');
	ga('require', 'ec');
	ga('set', '&cu', 'EUR');
}

/*
 * Step 1 - Cart
 * Step 2 - Delivery
 * Step 3 - Payment
 * Step 4 - Shipping info
 * Step 5 - Summary
 */
/*function ga_checkout(step) {
	ga('ec:setAction','checkout', {
		'step': step
	});
}*/

/*
$.fn.loader = function() {
	var overlay = '<div id="overlay"><div id="overlay-text"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></div></div>';

	if ($(this).find('#overlay').length > 0) {
		$('#overlay', this).replaceWith(overlay);
	} else {
		$(this).prepend(overlay);
	}
};*/